export const BLACK = "#1B191C"
export const GREY_DARK = "#2D2A2E"
export const GREY_MEDIUM = "#939293"
export const GREY_LIGHT = "#F4F4F2"
export const YELLOW = "#FFD866"
export const AQUA = "#78DCE8"
export const LIME = "#A9DC76"
export const PINK = "#FF6188"
export const LAVENDER = "#AB9DF2"

export default {
  // Fonts
  FONT_FAMILY: "Akkurat Mono",

  // Colors
  COLOR_BACKGROUND: GREY_DARK,
  COLOR_DIVIDER: BLACK,
  COLOR_BASE: GREY_LIGHT,
  COLOR_COMMENT: GREY_MEDIUM,
  COLOR_SYMBOL: GREY_MEDIUM,
  COLOR_STRING: YELLOW,
  COLOR_TYPE: AQUA,
  COLOR_VARIABLE: LAVENDER,
  COLOR_OPERATOR: PINK,
  COLOR_DATE: LAVENDER,
  COLOR_NUMBER: LAVENDER,

  // Font Sizes
  FONT_SIZE_XS: "14px",
  FONT_SIZE_S: "16px",
  FONT_SIZE_M: "18px",
  FONT_SIZE_L: "28px",
  FONT_SIZE_XL: "32px",
  FONT_SIZE_XXL: "48px",

  // Button
  BUTTON_BACKGROUND: GREY_LIGHT,
  BUTTON_TEXT: GREY_DARK,

  // Modal
  MODAL_CONTAINER_BACKGROUND: "rgba(0, 0, 0, .8)",
  MODAL_BACKGROUND: GREY_DARK,
  MODAL_HEADER_TEXT: GREY_LIGHT,
  MODAL_TEXT: GREY_MEDIUM,
  MODAL_CLOSE_BUTTON_COLOR: GREY_LIGHT,

  // Next Event Speaker
  SPEAKER_BACKGROUND: GREY_MEDIUM,

  // Past Event Photos Scrollbar
  PHOTO_SCROLLBAR_COLOR: GREY_LIGHT,
}
