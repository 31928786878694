import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Global } from "@emotion/core"
import { MQ_DESKTOP, columns, globalStyles } from "../styles"
import Logo from "../assets/icons/logo.svg"

const Container = styled.div`
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;

  ${MQ_DESKTOP} {
    padding-top: ${columns(0.5)};
    padding-left: ${columns(1)};
    padding-right: ${columns(1)};
  }
`

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MQ_DESKTOP} {
    min-height: ${columns(0.5)};
  }
`

const Layout = ({ children }) => {
  return (
    <Container>
      <Global styles={globalStyles} />
      <Navigation>
        <Link to="/">
          <Logo />
        </Link>
      </Navigation>
      <div>{children}</div>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
