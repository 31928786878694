import styled from "@emotion/styled"
import { css } from "@emotion/core"
import theme from "./theme"

export const DESKTOP_BREAKPOINT = 800
export const [MQ_DESKTOP] = [DESKTOP_BREAKPOINT].map(
  bp => `@media (min-width: ${bp}px)`
)

export const columns = count => `calc(${count}/14 * 100vw)`

export const NO_SCROLL = "no-scroll"
export const MOBILE_ONLY = "mobile-only"
export const DESKTOP_ONLY = "desktop-only"

export const globalStyles = css`
  * {
    margin: 0;
  }

  body {
    background-color: ${theme.COLOR_BACKGROUND};
    font-family: ${theme.FONT_FAMILY};
    font-size: ${theme.FONT_SIZE_S};
    color: ${theme.COLOR_BASE};
  }

  a {
    text-decoration: none;
  }

  button {
    border: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .${NO_SCROLL} {
    position: fixed;
    overflow: hidden;
    width: 100vw;
  }

  .${MOBILE_ONLY} {
    ${MQ_DESKTOP} {
      display: none;
    }
  }

  .${DESKTOP_ONLY} {
    @media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
      display: none;
    }
  }
`

export const stringStyle = css`
  color: ${theme.COLOR_STRING};

  &:before {
    color: ${theme.COLOR_SYMBOL};
    content: "“";
  }

  &:after {
    color: ${theme.COLOR_SYMBOL};
    content: "”";
  }
`

export const commentStyle = css`
  color: ${theme.COLOR_COMMENT};
  font-weight: normal;

  &:before {
    content: "// ";
  }
`

export const greyButtonStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;

  &:hover {
    &:before {
      opacity: 0.08;
    }
  }

  &:before {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: ${theme.SPEAKER_BACKGROUND};
    opacity: 0.05;
    border-radius: 8px;
    content: "";
  }
`

export const SectionContainer = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;

  ${MQ_DESKTOP} {
    padding-top: ${columns(1)};
    padding-bottom: ${columns(1)};
  }
`

export const NumValue = styled.span`
  color: ${theme.COLOR_NUMBER};
`

export const StringValue = styled.span`
  ${stringStyle}
`

export const Comment = styled.p`
  ${commentStyle}
`

export const Text = styled.span`
  color: ${({ color }) => color || "inherit"};
`
